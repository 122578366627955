<template>
  <div class="asset-page content" :class="typeClass">
    <BaseBreadcrumbs :breadcrumbs="isCms ? cmsBreadCrumbs : breadcrumbs" />
    <div class="flex-lg">
      <AssetThumbnail :asset="asset" />
      <div>
        <h1>{{ this.asset.name }}</h1>
        <div class="asset-tag">{{ type }}</div>
        <div class="asset-controls">
          <button
            v-if="!isCms"
            class="btn btn-favorite"
            :class="{ active: asset.assetFavoriteFlag }"
            @click="assetFavorited"
          >
            <IconFavorite />
            {{ asset.assetFavoriteFlag ? 'Remove from favorites' : 'Add to favorites' }}
          </button>
          <div class="break"></div>
          <div v-if="asset.isDownloadEnabled" class="download-container">
            <AssetDownloadButton v-if="type === 'Image' || type === 'PDF'" :downloadLink="asset.downloadLink" />
            <AssetVideoButton v-if="type === 'Video'" :downloadLink="asset.downloadLink" />
            <AssetMp3Button v-if="type === 'MP3'" :downloadLink="asset.downloadLink" :name="asset.name" />
          </div>
          <button class="btn btn-copy-link" @click="copyToClipboard(asset.downloadLink)">
            <IconShare />
            Copy link to share
            <BaseTooltip v-if="isTooltipActive">Link copied!</BaseTooltip>
          </button>
          <button class="btn btn-report" @click="openReportModal">
            <IconReport />
            Report a problem
          </button>
          <router-link v-if="isCms" :to="`/portal/cms/asset/edit/${assetId}`" class="btn">
            <IconEdit />
            Edit Asset
          </router-link>
        </div>
        <hr />
        <div class="spec-row">
          <div>
            <strong>Vehicle Model</strong>
            <div class="asset-tag">{{ asset.assetModelYear.modelName }}</div>
          </div>
          <div>
            <strong>Vehicle Year</strong>
            <div class="asset-tag">{{ asset.assetModelYear.modelYear }}</div>
          </div>
        </div>
        <div class="spec-row">
          <div>
            <strong>Job Role</strong>
            <div>
              <span v-for="(jobRole, index) in asset.assetJobRoleCategories" :key="jobRole.id">
                {{ jobRole.name }}<span v-if="index !== asset.assetJobRoleCategories.length - 1">,</span>
              </span>
            </div>
          </div>
          <div>
            <strong>Category</strong>
            <div>
              <span v-for="(category, index) in asset.assetCategories" :key="category.id">
                {{ category.name }}<span v-if="index !== asset.assetCategories.length - 1">,</span>
              </span>
            </div>
          </div>
          <div v-if="asset.useOwnerName">
            <strong>Submitted By</strong>
            <div>
              {{ asset.assetUser.firstName }} {{ asset.assetUser.lastName }}, {{ asset.assetUser.dealershipName }}
            </div>
          </div>
        </div>
        <div class="description" v-html="asset.description"></div>
      </div>
    </div>
    <BaseModal ref="reportModal" :modalType="'report'" @modal-open="reportModalOpened" @modal-close="reportModalClosed">
      <template v-slot:modal-content>
        <AssetReport :assetId="assetId" @close-report-modal="closeReportModal($event)" />
      </template>
    </BaseModal>
    <div class="toast-container">
      <BaseToast v-if="toastMessage.isShown" :toast="toastMessage" />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import BaseBreadcrumbs from '@/components/BaseBreadcrumbs.vue';
import BaseTooltip from '@/components/BaseTooltip.vue';
import BaseModal from '@/components/BaseModal.vue';
import BaseToast from '@/components/BaseToast.vue';
import AssetDownloadButton from '@/components/AssetDownloadButton.vue';
import AssetVideoButton from '@/components/AssetVideoButton.vue';
import AssetMp3Button from '@/components/AssetMp3Button.vue';
import AssetThumbnail from '@/components/AssetThumbnail.vue';
import AssetReport from '@/components/AssetReport.vue';
import IconFavorite from '@/assets/icons/favorite_icon.svg';
import IconShare from '@/assets/icons/share_icon.svg';
import IconReport from '@/assets/icons/jsconfig.svg';
import IconEdit from '@/assets/icons/edit.svg';

export default {
  components: {
    BaseBreadcrumbs,
    BaseTooltip,
    BaseModal,
    BaseToast,
    AssetDownloadButton,
    AssetVideoButton,
    AssetMp3Button,
    AssetThumbnail,
    AssetReport,
    IconFavorite,
    IconShare,
    IconReport,
    IconEdit,
  },
  props: {
    assetId: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      asset: {
        name: '',
        assetType: {
          name: '',
        },
        assetModelYear: {},
        assetUser: {
          dealershipName: 'Dealership Name',
        },
      },
      isTooltipActive: false,
      toastMessage: {
        status: 'danger',
        message: '',
        isShown: false,
      },
      cmsBreadCrumbs: [],
    };
  },
  computed: {
    ...mapGetters(['isFavoritesPageBreadcrumb']),
    breadcrumbs() {
      let firstBreadcrumb = {};
      if (!this.isFavoritesPageBreadcrumb) {
        firstBreadcrumb = { title: 'Content Library', link: '/' };
      } else {
        firstBreadcrumb = { title: 'Favorites', link: '/favorites' };
      }
      return [firstBreadcrumb, { title: this.asset.name, link: `/asset/${this.assetId}` }];
    },
    type() {
      return this.asset.assetType.name;
    },
    typeClass() {
      return `asset-full-type--${this.asset.assetType.name.toLowerCase()}`;
    },
    isCms() {
      return this.$route.path.includes('/cms/');
    },
  },
  watch: {
    $route() {
      this.getAsset();
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name) {
        vm.cmsBreadCrumbs.push({ title: from.name, link: from.path });
        vm.cmsBreadCrumbs.push({ title: to.name, link: to.path });
      } else {
        vm.cmsBreadCrumbs = [
          { title: 'Audi Collection', link: '/portal/cms/audi-collection' },
          { title: 'Edit Asset', link: to.path },
        ];
      }
    });
  },
  created() {
    this.getAsset();
  },
  methods: {
    getAsset() {
      axios.get(`${process.env.VUE_APP_API_URL}/assets/${this.assetId}`).then((response) => {
        this.asset = response.data.data.asset;
      });
    },
    assetFavorited() {
      axios
        .post(`${process.env.VUE_APP_API_URL}/users/favorite`, {
          assetId: this.asset.id,
          isFavorite: this.asset.assetFavoriteFlag ? 0 : 1,
        })
        .then(() => {
          this.asset.assetFavoriteFlag = !this.asset.assetFavoriteFlag;
        });
    },
    copyToClipboard(str) {
      /* ——— Derived from: https://hackernoon.com/copying-text-to-clipboard-with-javascript-df4d4988697f
                   improved to add iOS device compatibility——— */
      const el = document.createElement('textarea'); // Create a <textarea> element

      const storeContentEditable = el.contentEditable;
      const storeReadOnly = el.readOnly;

      el.value = str; // Set its value to the string that you want copied
      el.contentEditable = true;
      el.readOnly = false;
      el.setAttribute('readonly', false); // Make it readonly false for iOS compatability
      el.setAttribute('contenteditable', true); // Make it editable for iOS
      el.style.position = 'absolute';
      el.style.left = '-9999px'; // Move outside the screen to make it invisible
      document.body.appendChild(el); // Append the <textarea> element to the HTML document
      const selected =
        document.getSelection().rangeCount > 0 // Check if there is any content selected previously
          ? document.getSelection().getRangeAt(0) // Store selection if found
          : false; // Mark as false to know no selection existed before
      el.select(); // Select the <textarea> content
      el.setSelectionRange(0, 999999);
      document.execCommand('copy'); // Copy - only works as a result of a user action (e.g. click events)
      document.body.removeChild(el); // Remove the <textarea> element
      if (selected) {
        // If a selection existed before copying
        document.getSelection().removeAllRanges(); // Unselect everything on the HTML document
        document.getSelection().addRange(selected); // Restore the original selection
      }

      el.contentEditable = storeContentEditable;
      el.readOnly = storeReadOnly;
      this.isTooltipActive = true;
      setTimeout(() => {
        this.isTooltipActive = false;
      }, 5000);
    },
    openReportModal() {
      this.$refs.reportModal.openModal();
    },
    closeReportModal(isReportSent = false) {
      this.$refs.reportModal.hideModal();
      if (isReportSent) {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
        this.toastMessage.status = 'success';
        this.toastMessage.message = 'Your report has been submitted successfully.';
        this.toastMessage.isShown = true;
      }
    },
    reportModalOpened() {
      // eslint-disable-next-line
      console.log('report modal opened');
    },
    reportModalClosed() {
      // eslint-disable-next-line
      console.log('report modal closed');
    },
  },
};
</script>

<style lang="scss" scoped>
.asset-page {
  position: relative;

  .flex-lg {
    @include bp-lg-laptop {
      display: flex;
      flex-wrap: wrap;
    }
    > div {
      flex: 1 0 0%;
    }
  }
  .asset-image {
    margin-bottom: 1.25rem; // 20px
    @include bp-lg-laptop {
      max-width: 30%;
      margin-right: 3.125rem; // 50px
    }
  }
  h1 {
    margin-bottom: 0.625rem; // 10px
    font-family: $extended;
    font-size: 1rem; // 16px
    @include bp-lg-laptop {
      margin-bottom: 0.9375rem; // 15px
      font-size: 1.875rem; // 30px
    }
  }
  ::v-deep .btn {
    padding: 0;
    font-family: $wide;
    font-size: 0.875rem; // 14px
    color: $black;
    &:hover {
      color: $grey--dark;
    }
    svg {
      position: relative;
      top: -1px;
      width: 1.45em;
      height: 1.45em;
      margin-right: 0.9375rem; // 15px
      fill: currentColor;
    }
  }
  .btn-favorite {
    &.active {
      font-weight: bold;
    }
  }
  .btn-copy-link {
    position: relative;
  }
  hr {
    height: 2px;
    margin-top: 1.875rem; // 30px
    margin-bottom: 1.875rem; // 30px
    color: $grey--light;
    background-color: currentColor;
    border: 0;
  }
  .asset-controls {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 1.25rem; // 20px
    @include bp-md-tablet {
      justify-content: flex-start;
    }
    @include bp-lg-laptop {
      justify-content: space-between;
    }
    > .break {
      display: block;
      width: 100%;
      @include bp-lg-laptop {
        display: none;
      }
    }
    ::v-deep .btn {
      margin-bottom: 0.9375rem; // 15px
      &:not(:last-child) {
        @include bp-md-tablet {
          margin-right: 2.5rem; // 40px
        }
      }
    }
  }
  .spec-row {
    display: flex;
    flex-wrap: wrap;
    font-size: 0.75rem; // 12px
    > div {
      width: 50%;
      @include bp-md-tablet {
        max-width: 165px;
      }
    }
    strong {
      display: block;
      margin-top: 1.25rem; // 20px
      margin-bottom: 0.625rem; // 10px
      @include bp-md-tablet {
        margin-top: 1.875rem; // 30px
      }
    }
  }
  .description {
    margin-top: 2.5rem; // 40px
    font-size: 0.75rem; // 12px
    @include bp-md-tablet {
      font-size: 0.875rem; // 14px
    }
  }
}
</style>
