<template>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li v-for="(breadcrumb, index) in breadcrumbs" :key="index" :class="{ active: index === breadcrumbs.length - 1 }">
        <router-link v-if="index !== breadcrumbs.length - 1" :to="breadcrumb.link">{{ breadcrumb.title }}</router-link>
        <template v-else>{{ breadcrumb.title }}</template>
      </li>
    </ol>
  </nav>
</template>

<script>
export default {
  props: {
    breadcrumbs: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
nav {
  width: 100%;
}
.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1.875rem; // 30px
  font-family: $wide;
  font-size: 0.75rem; // 12px
  list-style: none;
  @include bp-md-tablet {
    font-size: 0.875rem; // 14px;
  }
  li + li {
    padding-left: 0.5rem; // 8px
    @include bp-md-tablet {
      padding-left: 1rem; // 16px
    }
    &::before {
      float: left;
      padding-right: 0.5rem;
      color: #6c757d;
      content: '>';
      @include bp-md-tablet {
        padding-right: 1rem; // 16px
      }
    }
  }
  a {
    color: $grey--medium;
    text-decoration: none;
  }
}
</style>
