<template>
  <div class="toast" :class="[hiding, toast.status]" v-if="isShown">
    <IconError v-if="toast.status === 'danger'" class="toast-icon" />
    <IconSuccess v-if="toast.status === 'success'" class="toast-icon" />
    <div class="toast-message">
      {{ toast.message }}
    </div>
    <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close" @click="hideToast">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.907 11.907" aria-hidden="true">
        <path data-name="Path 271" d="M.354 11.554l11.2-11.2m-11.2 0l11.2 11.2" fill="none" />
      </svg>
    </button>
  </div>
</template>

<script>
import IconError from '@/assets/icons/error.svg';
import IconSuccess from '@/assets/icons/success.svg';

export default {
  components: {
    IconError,
    IconSuccess,
  },
  name: 'BaseToast',
  props: {
    toast: {
      status: 'danger',
      message: '',
      isShown: false,
    },
  },
  data() {
    return {
      isShown: true,
      show: false,
      hiding: false,
    };
  },
  watch: {
    toast() {
      this.isShown = true;
    },
  },
  methods: {
    hideToast() {
      this.hiding = true;
      setTimeout(() => {
        this.isShown = false;
        this.hiding = false;
      }, 150);
    },
  },
};
</script>

<style lang="scss" scoped>
.toast {
  position: relative;
  display: flex;
  padding: 1.375rem 2rem 1.375rem 1.375rem; // 22px 32px
  font-size: 0.75rem; // 12px
  color: $grey--dark;
  background-color: $white;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  animation: fadein 0.5s;
  &.hiding {
    animation: fadeout 0.5s;
  }
}
.toast-icon {
  width: 1.375rem; // 22px
  height: auto;
  margin-right: 0.75rem; // 12px
}
.btn-close {
  position: absolute;
  top: 0.75rem; // 12px
  right: 0.75rem; // 12px
}
@keyframes fadein {
  from {
    left: -30px;
    opacity: 0;
  }
  to {
    left: 0;
    opacity: 1;
  }
}
@keyframes fadeout {
  from {
    left: 0;
    opacity: 1;
  }
  to {
    left: -30px;
    opacity: 0;
  }
}
</style>
