<template>
  <div class="modal-report-form">
    <h2>Report a Problem</h2>
    <p>Lorem Ipsum Merchandising</p>
    <form autocomplete="off" method="post" v-on:submit.prevent="submitForm">
      <div class="form-group" :class="{ 'has-error': errors.message }">
        <label for="report-message" class="form-label">Message<sup>*</sup></label>
        <textarea
          v-model="message"
          type="text"
          class="form-control"
          id="report-message"
          placeholder="Type the problem here..."
          rows="5"
          :class="{ 'is-invalid': errors.message }"
        ></textarea>
        <template v-if="errors.message">
          <div v-for="message in errors.message.messages" :key="message" class="invalid-feedback">
            {{ message }}
          </div>
        </template>
      </div>
      <div class="btn-container">
        <button class="btn btn-primary" type="submit">Submit Feedback</button>
        <button class="btn btn-outline-primary" type="button" @click="cancel">Cancel</button>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    assetId: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      message: '',
      errors: {},
    };
  },
  methods: {
    submitForm() {
      if (this.validateForm()) {
        axios
          .post(`${process.env.VUE_APP_API_URL}/assets/${this.assetId}/report`, { message: this.message })
          .then(() => {
            this.message = '';
            this.$emit('close-report-modal', true);
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.log(error.response);
          });
      }
    },
    validateForm() {
      this.errors = {};
      if (this.message === '') {
        this.errors.message = {};
        this.errors.message.messages = [];
        this.errors.message.messages.push('Please enter a message to report.');
      }
      return Object.keys(this.errors).length === 0;
    },
    cancel() {
      this.message = '';
      this.errors = {};
      this.$emit('close-report-modal', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-report-form {
  h2 {
    font-size: 1rem;
    font-size: bold;
  }
  .btn-container {
    @include bp-md-tablet {
      display: flex;
      justify-content: flex-end;
    }
  }
  form {
    @include bp-md-tablet {
      margin-top: 2.5rem; // 40px
    }
  }
  .btn {
    display: block;
    width: 100%;
    &:not(:last-child) {
      margin-bottom: 0.625rem; // 10px
    }
    @include bp-md-tablet {
      display: inline-block;
      width: auto;
      &:not(:last-child) {
        order: 1;
        margin-bottom: 0;
        margin-left: 0.625rem; // 10px
      }
      &:last-child {
        order: 0;
      }
    }
  }
}
</style>
